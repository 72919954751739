.flex_box[data-v-78d0791e] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancel[data-v-78d0791e] {
  color: #17a2b8;
  cursor: pointer;
  font-size: 12px;
  margin-left: 40px;
}
[data-v-78d0791e] .el-collapse-item__content {
  padding-bottom: 0;
}
[data-v-78d0791e] .el-collapse-item__header {
  height: 30px;
  line-height: 30px;
}
.form_style .el-form-item[data-v-78d0791e] {
  margin-bottom: 5px;
}
.bank-table-header[data-v-78d0791e] {
  border: 1px solid #b9b9b9;
  display: flex;
  overflow: hidden;
  font-size: 13px;
  margin: 5px 0;
}
.bank-table-header > div[data-v-78d0791e] {
  border-right: 1px solid #b9b9b9;
  line-height: 24px;
  padding: 0 2px;
}
.bank-table-header > div[data-v-78d0791e]:last-child {
  border-right: 0;
}
.bank_header[data-v-78d0791e] {
  width: calc(100% - 41px);
  margin-left: 24px;
  display: flex;
  font-size: 13px;
  background: var(--themeColor, #17a2b8);
  color: #fff;
  height: 30px;
  line-height: 30px;
}
.bank_header > div[data-v-78d0791e] {
  text-align: center;
  border-right: 1px solid #b9b9b9;
}
.bank_header > div[data-v-78d0791e]:last-child {
  border-right: 0;
}
[data-v-78d0791e] .el-tree-node__content {
  width: 100%;
  height: auto;
}
[data-v-78d0791e] .el-tree-node__label {
  width: 100%;
}
.icon-jiahao[data-v-78d0791e] {
  color: #17a2b8;
}
.icon-shanchu[data-v-78d0791e] {
  color: red;
  margin-left: 8px;
}
.ccyc[data-v-78d0791e] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #212529;
}
.other_name[data-v-78d0791e] {
  display: flex;
  justify-content: space-between;
}
.center[data-v-78d0791e] {
  text-align: center;
}
.width_10[data-v-78d0791e] {
  width: 10%;
}
.width_8[data-v-78d0791e] {
  width: 8%;
}
.name_left[data-v-78d0791e] {
  width: 90%;
}
.width_5[data-v-78d0791e] {
  width: 5%;
}
.width_15[data-v-78d0791e] {
  width: 15%;
}
.width_26[data-v-78d0791e] {
  width: 26%;
}
.input_size[data-v-78d0791e] {
  width: 95%;
}
[data-v-78d0791e] .el-tree-node__content {
  width: 100%;
  height: auto;
}
[data-v-78d0791e] .el-tree-node__content:hover {
  background-color: #e4f2ff !important;
}
[data-v-78d0791e] .el-tree-node__content:focus {
  background-color: #e4f2ff !important;
}